<template>
  <div class="off-canvas-wrapper" :class="{ 'scrollNone': activeNotification }">
    <site-header v-on:open-notifications="openNotifications"/>
    <div
      data-off-canvas
      id="offCanvas"
      class="off-canvas position-left reveal-for-medium"
      v-bind:class="{ 'menu-collapse': collapse }">
      <div class="site-logo">
        <div class="logo" v-on:click="collapse = !collapse"></div>
      </div>
      <div class="sidebar-wrapper">
        <nav class="sidebar-menu" data-close="offCanvas">
          <ul>
            <li v-for="(item, index) in items" v-bind:key="index">
              <router-link v-bind:to="item.href">
                <div class="icon">
                  <component v-bind:is="item.icon"/>
                </div>
                <span class="title">{{ item.title }}</span>
              </router-link>
            </li>
            <li>
              <a :href="messagesUrl" target="_blank">
                <div class="icon">
                  <open-in-new-icon/>
                </div>
                <span class="title">{{ $t('app.messages') }}</span>
              </a>
            </li>
            <li>
              <a :href="teamUrl" target="_blank">
                <div class="icon">
                  <open-in-new-icon/>
                </div>
                <span class="title">{{ $t('app.team') }}</span>
              </a>
            </li>
            <li>
              <a :href="dataBankUrl" target="_blank">
                <div class="icon">
                  <open-in-new-icon/>
                </div>
                <span class="title">{{ $t('app.data-bank') }}</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div
      data-off-canvas-content
      class="main-content grid-container full"
      v-bind:class="{ 'canvas-collapse': collapse }">
      <div class="grid-x">
        <div class="cell">
          <keep-alive>
            <router-view/>
          </keep-alive>
        </div>
      </div>
    </div>
    <menu-notification v-if="!isLogin" ref="notificationsMenu"/>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  components: {
    SiteHeader: () => import('@/components/Header.vue'),
    MenuNotification: () => import('@/components/MenuNotification.vue'),
  },
  data() {
    return {
      items: [
        {
          title: this.$t('app.dashboard'),
          href: '/dashboard',
          icon: 'view-dashboard-icon',
        },
        {
          title: this.$t('app.sendTemplates'),
          href: '/send-templates',
          icon: 'whatsapp',
        },
        {
          title: this.$t('app.campaigns'),
          href: '/campaigns',
          icon: 'bullhorn-icon',
        },
      ],
    };
  },
  mounted() {
    this.collapse = window.screen.width < 1024;
  },
  computed: {
    collapse: {
      get() {
        return this.$store.state.dashboard.collapse;
      },
      set(value) {
        this.toogleCollapse(value);
      },
    },
    isLogin() {
      return this.$route.name === 'login';
    },
    activeNotification() {
      return this.$store.state.notification.toogleMenu;
    },
    messagesUrl() {
      return `${process.env.VUE_APP_REDIRECT_URL}/conversations`;
    },
    teamUrl() {
      return `${process.env.VUE_APP_REDIRECT_URL}/team`;
    },
    dataBankUrl() {
      return `${process.env.VUE_APP_REDIRECT_URL}/data-bank`;
    },
  },
  methods: {
    ...mapMutations({
      toogleCollapse: 'dashboard/toogleCollapse',
    }),
    openNotifications() {
      this.$refs.notificationsMenu.displayMenu();
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~styles/components/_site-navigation.scss';
</style>
